<template>
    <div class="col-md-12">
        <div class="col-md-12 mt-20">
            <div class="col-md-12 row">
                <div class="col-md-9">
                    <h3 class="page-header">
                        <i class="fa fa fa-rupee animated bounceInDown show-info" style="font-size: 24px;"></i>
                        Add Expense
                    </h3>
                </div>
                <!-- <div class="col-md-3"  >
                    <button class="btn btn-success" >+ Add New</button>
                    <button class="btn btn-info ml-10" >Refresh</button>
                </div> -->
            </div>
        </div>
        <div class="col-md-12">
            <div class="panel panel-cascade">
                <div class="panel-body">
                    <div class="form-horizontal cascde-forms">
                        <div class="col-md-12">
                            <div class="form-horizontal cascde-forms">
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="subcategory" class="col-form-label control-label">Expense Head
                                            <span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-control form-cascade-control input-small"
                                            v-model="expenseheadid" @change="chooseHead($event)">
                                            <option :value="0">Select</option>
                                            <option v-for="item in EXPENSE_HEADS" :key="item.id" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="gender" class="col-form-label control-label">Expense Sub Head
                                            <span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-control form-cascade-control input-small"
                                            v-model="expensesubheadid" @change="chooseExpenseSubHead($event)">
                                            <option value="0">Select</option>
                                            <option v-for="item in filteredsubheads" :key="item.id" :value="item.id">
                                                {{ item.name }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="gender" class="col-form-label control-label">Date</label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="date" v-model="transactiondate"
                                            class="form-control form-cascade-control input-small" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="gender" class="col-form-label control-label">Amount
                                            <span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="number" v-model="amount"
                                            class="form-control form-cascade-control input-small" />
                                    </div>
                                </div>
                                <div class="form-group row" v-if="expenseheadid == 1">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="gender" class="col-form-label control-label">Remaining Amount
                                            <span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-control form-cascade-control input-small" >{{ this.remainingamount ?? 0 }}</div>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="gender" class="col-form-label control-label">Payment Mode
                                            <span style="color: rgba(255, 0, 0, 0.67)">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-6">
                                        <select class="form-control form-cascade-control input-small" v-model="mode" >
                                            <option :value="0">Select</option>
                                            <option v-for="item in registers" :key="item.id" :value="item.id">{{item.name}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <div class="col-lg-4 col-md-3">
                                        <label for="gender" class="col-form-label control-label">Description</label>
                                    </div>
                                    <div class="col-md-6">
                                        <textarea v-model="description"
                                            class="form-control form-cascade-control input-small" rows="3"></textarea>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <button class="btn btn-success" @click="saveExpense()" :disabled="isSubmit" >Save</button>
                                    <button class=" btn btn-danger ml-5" @click="cancel()">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ExpenseList ref="listFun"></ExpenseList>
    </div>
</template>
<script>
import axios from 'axios'
import Constants from '../../components/utilities/Constants.vue';
import { mapGetters } from 'vuex'
import ExpenseList from './ExpenseList.vue';
// import moment  from 'moment'
export default {
    components:{ExpenseList},
    mixins: [Constants],
    data() {
        return {
            
            expensesubheadid: 0,
            expenseheadid: 0,
            amount: 0,
            description: '',
            transactiondate: '',
            filteredsubheads: [],
            id: 0,
            mode: 0,
            isSubmit: false,
            remainingamount:0,

        }
    },
    mounted() {
        // this.refresh() 
        this.$store.dispatch("fetchregisters")
        this.$store.dispatch("fetchExpenseSubHeads")
        this.$store.dispatch('fetchemployees')

    },
    computed:{
        ...mapGetters([
		      'employees','registers','selectedstoreid','expensesubheads'
	    ])
    },
    methods: {

        // refresh() {
        //     this.$store.commit('assignloadingstatus', 1)
        //     axios.post('api/expense/sub/head/fetch')
        //         .then(response => this.processHeadResponse(response.data))
        //         .catch(error => this.showError(error));
        // },
        // processHeadResponse(data) {
        //     this.$store.commit('assignexpensesubheads',data.subheads)
        //     // this.expensesubheads = data.subheads
        //     this.$store.commit('assignloadingstatus', 0)

        // },
        chooseHead(event) {
            this.amount = 0
            this.remainingamount = 0
            if(event.target.value == 1 || event.target.value == 2){
                this.filteredsubheads = this.employees 
            }else{
                this.filteredsubheads = this.expensesubheads.filter(o => o.expenseheadid == event.target.value)
            }
        },
        chooseExpenseSubHead(event){
            console.log(event.target.value)
            this.amount = 0
            this.remainingamount = 0
            if(this.expenseheadid==1 ){
                let param = {
                    userid: event.target.value,
                }
                axios.post('api/salary/slip/fetch',param)
                    .then((response) => {
                        if (response.data.salaryslips?.length > 0) {
                          
                            this.amount = response.data?.salaryslips[0]?.totalamount
                            // let advance = response.data?.advance
                            // this.remainingamount = parseFloat(parseFloat(this.amount) - parseFloat(advance)).toFixed(2)
                        }else {
                            this.$notify({ text:'No result found', type: 'warn'})
                        }
                        this.$store.commit('assignloadingstatus', 0)
                    }).catch((error) => console.log(error))
                let param1 = {id : event.target.value} 
                axios.post('api/customer/fetchbalance/userid2',param1)
                    .then((response) => {
                            this.remainingamount = response.data.balance ?? 0
                    }).catch((error) => console.log(error))
            }
        },
        saveExpense() {
            if (this.expenseheadid == 0) {
                alert("Select expense head ")
            } else if (this.expensesubheadid==0){
                alert('Select expense sub head')
            } else if (this.transactiondate ==''){
                alert('Select date')
            } else if (this.mode==0 || this.mode=='0') {
                alert('Select mode')
            } else {
                this.isSubmit = true
                let param = {
                    id: this.id, expenseheadid:this.expenseheadid, transactiondate:this.transactiondate,
                    fy: this.getCurrentFinancialYear(), selectedstoreid:this.selectedstoreid,mode: this.mode,
                    amountwithtax: this.amount,description:this.description,
                    use_in_payslip: this.expenseheadid == 2 ?1:0,
                    employeeid: this.expenseheadid ==1 || this.expenseheadid == 2 ? this.expensesubheadid : 0,
                    issalary: this.expenseheadid == 1 ? 1 : 0,
                    expensesubheadid:this.expensesubheadid
                }
                console.log("param ",param)                                  
                axios.post('api/expense/post', param)
                    .then(response => this.processSaveExpenseResponse(response.data))
                    .catch(error => console.log(error))
            }
        },
        processSaveExpenseResponse(data) {
            console.log(data)    
            this.$refs.listFun.refresh();
            this.$notify({ text:'Submit successfully', type:'success'})
            this.cancel()
        },
        cancel(){
            this.id = 0
            this.expensetypeheadid = 0
            this.expenseheadid = 0
            this.amount = 0
            this.description = ''
            this.mode=0
            this.transactiondate = ''
            this.filteredsubheads = []
            this.isSubmit = false
            this.remainingamount = 0
            this.$store.commit('assignloadingstatus', 0)
        }
    },
}
</script>