<template>
    <div class="mt-30">
        <div class=" col-md-12 row">
            <div class="col-md-3">
                <label class="control-label">Date : From</label>
                <input class="form-control form-cascade-control input-small" v-model="fromdate" type="date" />
            </div>
            <div class="col-md-3">
                <label class="control-label">Date : To</label>
                <input class="form-control form-cascade-control input-small" v-model="todate" type="date" />
            </div>
            <div class="col-md-3">
                <label class="control-label">Expense Head</label>
                <select v-model="expenseheadid" class="form-control form-cascade-control input-small">
                    <option :value="0">Select </option>
                    <option  v-for="expense in EXPENSE_HEADS" :key="expense.id" :value="expense.id">
                        {{ expense.name }}</option>
                </select>
            </div>
            <div class="col-md-3">
                <label class="control-label">Expense Sub Head</label>
                <select v-model="expensesubheadid" class="form-control form-cascade-control input-small">
                    <option :value="0">Select </option>
                    <option  v-for="expense in dynamicSubHead" :key="expense.id" :value="expense.id">
                        {{ expense.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-md-12 mt-20">
            <button class="btn btn-primary btn-sm" @click="refresh()">Submit</button>
            <button class="btn btn-danger btn-sm" @click="cancel()">Cancel</button>
        </div>
        <div class="col-md-12 mt-10">
            <div class="row">
                <div class="col-md-4">
                    <div class="total-div">
                        <label>Total Expense :</label>
                        <span class="order-val" style="background: blueviolet;">{{ totalexpense }}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 pt-10">
            <div class="">
                <table class="table ">
                    <thead>
                        <th>S.No.</th>
                        <th>Date</th>
                        <th>Exp. Head</th>
                        <th>Exp. Sub Head</th>
                        <th>Description</th>
                        <th>Amount</th>
                        <th>Paid by</th>
                        <th>Action</th>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in this.expenses" :key="item.id">
                            <td>{{ index + 1 }}</td>
                            <td>{{ moment(item.transactiondate).format('DD-MM-YYYY') }}</td>
                            <td><span>{{ EXPENSE_HEADS.find(o=>o.id==item.expenseheadid)?.name }}</span></td>
                            <td><span>{{ getExpenseSubHead(item) }}</span></td>
                            <td>{{ item.description }}</td>
                            <td>{{ item.amountwithtax }}</td>
                            <td><span v-if="item.mode != null">{{ item.mode.name }}</span></td>
                            <td>
                                <button class="badge bg-primary" @click="exportExcel(item)">Excel</button>
                                <button class="badge bg-danger ml-5" @click="deleteitem(item)">Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
import moment from 'moment';
import Constants from '../../components/utilities/Constants.vue';

export default {
    mixins:[Constants],
    data() {
        return {
            moment: moment,
            currentPage: 1,
            perPage: 50,
            selectedExpenseHead: 0,
            fromdate: '',
            todate: '',
            expenseheadid: 0,
            expensesubheadid: 0,
            dynamicSubHead: [],
            totalexpense: 0,

        }
    },
    mounted() {
        this.$store.dispatch('fetchemployees')
        this.refresh()
        this.initialize()
    },
    computed: {
        ...mapGetters([
            'employees','expensesubheads','expenses'
        ]),
        
    },
    methods: {
        initialize() {
            let check = true;
            if(check){
                setTimeout(() =>{
                    this.dynamicSubHead = []
                    let temp = this.expensesubheads
                    this.dynamicSubHead = temp.concat(this.employees)
                },1000)
                check=false
            }
        },
        refresh() {
            this.$store.commit('assignloadingstatus', 1)
            let param = { noofrec: this.perPage, currentpage: this.currentPage, fromdate:this.fromdate,
                todate: this.todate, expenseheadid: this.expenseheadid, expensesubheadid: this.expensesubheadid }
            axios.post('api/expenses/getlist', param)
                .then((response) => {
                    this.$store.commit('assignexpenses',response.data.expense)
                    this.totalexpense = response.data.totalexpense
                    this.$store.commit('assignloadingstatus', 0)
                }).catch((error) => console.log(error))
        },
        getExpenseSubHead(item){
            if(item.expenseheadid == 1 || item.expenseheadid == 2){
                return this.employees.find(o=>o.id==item.expensesubheadid)?.name ?? ''
            }else {
                return this.expensesubheads.find(o=>o.id == item.expensesubheadid)?.name ?? ''
            }
        },
        cancel(){
            this.fromdate = ''
            this.todate = ''
            this.expenseheadid = 0
            this.expensesubheadid = 0
            this.refresh()
        },
        exportExcel(item) {
            console.log(item)
            let param = {
                id: item.id,
                employeeid: item.employeeid,
            }
            this.$http({
                url: 'api/expense/excel/export',
                method: 'post',
                responseType: 'blob',
                data: param,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    mode: 'no-cors'
                }
            })
                .then(response => {
                    let blob = new Blob([response.data], { type: 'application/vnd.ms-excel' })
                    let link = document.createElement('a')
                    link.href = window.URL.createObjectURL(blob)
                    link.download = 'expense_slip.xlsx'
                    link.click()
                })
        },
     
    }
}
</script>